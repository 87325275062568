import React from "react"
import { graphql } from "gatsby"
import Image, { ImageProps } from "../components/Image"
import convert from "../utils/convert"

type KvaliteterPageTemplateProps = {
  start: {
    image: ImageProps,
    title: string,
    text: string
  },
  fasiliteter: {
    image: ImageProps,
    title: string,
    text: string,
    inngangsparti1: ImageProps,
    inngangsparti2: ImageProps,
    gallery: ImageProps[]
  },
  interior: {
    title: string,
    text1: string,
    image: ImageProps,
    text2: string
  },
  muligheter: {
    title: string,
    text: string,
    image: ImageProps
  }
}

const KvaliteterPageTemplate: React.FC<KvaliteterPageTemplateProps> = ({
  start, fasiliteter, interior, muligheter
}) => {
  return (
    <main id="kvaliteter-page">
      <Image
        src={start.image.src}
        alt={start.image.alt}
        className="h-screen-66 xl:h-auto object-cover object-center mx-auto w-full"
        loading="eager"
        fadeIn={false}
      />

      <section
        id="start"
        className="py-16 sm:py-8"
      >
        <div className="mx-auto w-full max-w-3xl text-center">
          <h1 className="title">
            {start.title}
          </h1>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="font-semibold text-alt markdown max-w-xl mx-auto"
            dangerouslySetInnerHTML={{
              __html: convert(start.text)
            }}
          />
        </div>
      </section>

      <section
        id="fasiliteter"
        className="py-16 sm:py-8"
      >
        <Image
          className="w-full"
          src={fasiliteter.image.src}
          alt={fasiliteter.image.alt}
        />

        <div className="mx-auto w-full max-w-5xl my-16 sm:my-8">
          <div className="mx-auto w-full max-w-lg text-center px-6">
            <h2 className="subtitle">
              {fasiliteter.title}
            </h2>

            <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />
          </div>

          <div
            className="cc-3"
            dangerouslySetInnerHTML={{
              __html: convert(fasiliteter.text)
            }}
          />
        </div>

        <div className="flex items-center sm:block">
          <div className="flex-1 mr-8 sm:mr-0 sm:mb-8 sm:hidden">
            <Image
              className="w-full"
              src={fasiliteter.inngangsparti1.src}
              alt={fasiliteter.inngangsparti1.alt}
            />
          </div>

          <div className="flex-2">
            <Image
              className="w-full"
              src={fasiliteter.inngangsparti2.src}
              alt={fasiliteter.inngangsparti2.alt}
            />
          </div>
        </div>

        <div className="grid grid-columns-4 grid-gap-8 mt-8 lg:grid-gap-4 lg:mt-4 sm:grid-columns-2">
          {fasiliteter.gallery.map((image, index) => (
            <div
              key={index}
              className={`
                ${index === 0 ? "row-span-3 sm:row-span-2" : ""}
                ${index === 2 ? "row-span-2 sm:row-span-1" : ""}
                ${index === 0 ? "col-span-3 sm:col-span-2" : ""}
              `}
            >
              <Image
                className="w-full object-cover object-center h-full"
                src={image.src}
                alt={image.alt}
              />
            </div>
          ))}
        </div>
      </section>

      <section id="interior">
        <div className="w-full max-w-7xl flex items-center mx-auto md:block">
          <div className="flex-1">
            <div className="mx-auto w-full max-w-lg lg:ml-0 md:mr-0 md:max-w-full">

              <div className="mx-auto w-full md:max-w-2xl md:text-center">
                <h2 className="subtitle">
                  {interior.title}
                </h2>
              </div>

              <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px text-left md:mx-auto md:text-center" />

              <div className="markdown pr-32 lg:pr-8 md:pr-0">
                <div
                  className="font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: convert(interior.text1)
                  }}
                />

                <div
                  className="mt-8 sm:mt-4"
                  dangerouslySetInnerHTML={{
                    __html: convert(interior.text2)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex-1 lg:my-8">
            <Image
              className="ml-auto"
              src={interior.image.src}
              alt={interior.image.alt}
            />
          </div>
        </div>
      </section>

      <section
        id="muligheter"
        className="pt-16 pb-32 sm:pt-8 sm:pb-16"
      >
        <Image
          className="w-full object-cover object-center"
          src={muligheter.image.src}
          alt={muligheter.image.alt}
        />

        <div className="mx-auto w-full max-w-3xl mt-16 sm:mt-8">
          <div className="mx-auto w-full max-w-lg px-6">
            <h2 className="subtitle text-center">
              {muligheter.title}
            </h2>
          </div>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="cc-2"
            dangerouslySetInnerHTML={{
              __html: convert(muligheter.text)
            }}
          />
        </div>
      </section>
    </main>
  )
}

interface KvaliteterPageProps {
  data: {
    markdownRemark: {
      frontmatter: KvaliteterPageTemplateProps
    }
  }
}

const KvaliteterPage: React.FC<KvaliteterPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <KvaliteterPageTemplate
      start={frontmatter.start}
      fasiliteter={frontmatter.fasiliteter}
      interior={frontmatter.interior}
      muligheter={frontmatter.muligheter}
    />
  )
}

export default KvaliteterPage

export const kvaliteterPageQuery = graphql`
  query KvaliteterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        start {
          image {
            src
            alt
          }
          title
          text
        }

        fasiliteter {
          image {
            src
            alt
          }
          title
          text
          inngangsparti1 {
            src
            alt
          }
          inngangsparti2 {
            src
            alt
          }
          gallery {
            src
            alt
          }
        }

        interior {
          title
          text1
          image {
            src
            alt
          }
          text2
        }

        muligheter {
          title
          text
          image {
            src
            alt
          }
        }

      }
    }
  }
`
